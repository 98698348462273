import React from "react";

import { Layout } from "~/components";
import { ShuttlesAndCharters } from "~/views";

const HelitaxiiShuttlesAndCharters: React.FunctionComponent = () => {
  return (
    <Layout title="HeliTaxi - Shuttles & Charters">
      <ShuttlesAndCharters />
    </Layout>
  );
};

export default HelitaxiiShuttlesAndCharters;
